

export const subHeaderLinks = [
  {
    label: 'HOME',
    route: '/',
  },
  {
    label: 'ABOUT US',
    route: '/aboutUs',
  },
  {
    label: 'WHAT WE DO',
    route: '/whatwedo',
  },
  {
    label: 'GALLERY',
    route: '/gallery',
    subroutes:[{
      label: 'Photos',
      route: '/photos',
    },
    {
      label: 'Videos',
      route: '/videos',
    }
    ]
  },
  
    {
      label: 'CONTACT US',
      route: '/contactus',
    },
]

export const MobileLinks = [
  {
    label: 'Home',
    route: '/',
  },
  {
    label: 'ABOUT US',
    route: '/aboutUs',
  },
  {
    label: 'WHAT WE DO',
    route: '/whatwedo',
  },
  {
    label: 'PHOTOS',
    route: '/hrconsulting',
  },
  {
    label: 'VIDEOS',
    route: '/hrcompliance',
  },
  {
    label: 'Contact Us',
    route: '/cONTACTUS',
  },

]

export const HomeServices=[
{id:1,  
  heading:"Moving skilled professionals to the UK, Europe, Canada & Dubai is what we do best",
  text: `TOM Universal is a registered corporation with the Indian government; TOM Universal Pvt Ltd offers a variety of immigration services to help both corporate and individual clients with their visa-related problems. We help small and medium-sized businesses find Indian talent and are experts in processing visas for skilled workers. Our clientele come from the IT sector.\n We also work with individual clients and offer in-depth support for all types of immigration. We can assist you in realizing your immigration aspirations, whether you are thinking about travelling briefly, moving abroad for school, or joining a relative. We will take the time to learn about your needs since we understand how essential it is to seek reliable guidance and specialized services when it comes to immigration, and we will take the time to understand your needs in order to provide the best possible solution at a competitive price.`,
  img:"/assets/visa.png"
},
{id:2,
  heading:"Avoid being struck. Choose the right visa for your needs.",
  text:"At TOM Universal, we take pleasure in assisting IT and healthcare professionals who wish to immigrate to the UK, Europe, or Canada with the goal of settling down there permanently. We take pride in providing the most individualised immigration service possible, adjusting our recommendations to your needs and making sure that your case has the best possible chance of being approved. We process applications quickly and effectively, making sure that our clients are informed at every step.\n We are committed and have in-depth knowledge of the requirements for immigration to the UK, Europe, and Canada. We recognise how crucial it is to seek reliable assistance and qualified services when it comes to immigration, so we will take the time to learn about your needs and offer the most suitable solution at a reasonable cost.\n To guarantee that the application is granted, it is critical that the appropriate documentation be here in place.",
  img:"/assets/visa_appr.png"
},
{id:3,
  heading:"The UK Health Care Visa as Care Assistant",
  text:"The UK Health Care Visa is a type of visa introduced by the UK government to support the recruitment of skilled healthcare professionals from outside the European Union (EU) and European Economic Area (EEA). The Health Care Visa is specifically designed to help non-UK citizens with qualifications in healthcare to work in the UK National Health Service (NHS) or the UK private healthcare sector.\nAs a Care Assistant, the UK Health Care Visa would allow you to work in the UK in a care home or residential setting, providing support to patients who require assistance with day-to-day activities such as personal care, mobility and social engagement. You would be required to have a relevant qualification in health and social care and a minimum of six months' experience working in a care setting.",
  img:"/assets/health_care.png"
},
{id:4,
  heading:"IT Professional",
  text:"The IT Professional visa is a type of visa designed by the UK government for highly skilled IT professionals who want to work in the UK. This visa category is part of the UK's Points-Based System, which assesses applicants based on their skills, experience, and qualifications.\nTo be eligible for an IT Professional visa, applicants must have a job offer from a UK employer that is on the list of eligible sponsors. They must also have a Certificate of Sponsorship from their employer and score enough points on the Points-Based System.\nThe Points-Based System takes into account several factors, including the applicant's qualifications, previous earnings, and English language proficiency. Applicants must score a minimum of 70 points to be eligible for the IT Professional visa.",
  img:"/assets/it_prof.png"
},
{id:5,
  heading:"Recruitment",
  text:"The leading destination for professionals seeking their perfect career in the United Kingdom. We understand that finding the right job can be a challenging and time-consuming process. That's why we're here to simplify it for you.\nAt Tom Universal, we pride ourselves on our extensive network of trusted employers spanning various industries. Whether you're an experienced professional looking for a senior executive role or a fresh graduate searching for your first opportunity, we have connections that match your aspirations.\nOur team of experienced recruiters and advanced algorithms work tirelessly to ensure that we deliver personalized job matches tailored to your unique skills, interests, and career goals. We believe that finding the right fit is crucial for long-term success and job satisfaction.\nWith our deep knowledge of the UK job market and industry insights, we stay ahead of the latest trends and opportunities. This expertise allows us to provide you with valuable guidance and support throughout your job search journey. We're committed to helping you navigate the competitive landscape and make informed career decisions.",
  img:"/assets/rec.jpg"
},
]

export const keyServices=[
{id:1,  
  heading:"Food Distribution",
  text: "Food donation is considered to be the purest form of all donations made. We distribute quality food regularly near Governament hospitals and people stay on streents of city.",
  img:"/assets/images/food_donation.jpg",
  route: '/whatwedo',
},
{id:3,
  heading:"Blankets Distribution",
  text:"We are providing blankets to people living on the streets every winter. Our organization runs Blanket Donation Campaign, where we provide high-quality warm blankets to the poor people.",
  img:"/assets/images/blankets_donation.jpg",
  route: '/whatwedo',
},
{id:2,
  heading:"Old Age Home Service",
  text: "Our team recognizes the complexities of the challenges that come with aging. For this reason, Cornerstone is proud to offer personalized care thats within reach.",
  img:"/assets/images/oldage_home.jpg",
  route: '/whatwedo',
},
{id:5,
  heading:"Medical Service",
  text:"The association acts as a reliable source of information about medical tourism, providing education and guidance to patients interested in seeking healthcare services.",
  img:"/assets/images/medical_service.jpg",
  route: '/whatwedo',
},
{id:4,
  heading:"Books for Children",
  text:"We provide educational material consisting of text books, notebooks, geometry box, pencils, pens, and school uniforms, clothes, shoes, school bag, lunch box.",
  img:"/assets/images/books.jpg",
  route: '/whatwedo',
},
{id:6,
  heading:"Women Trainings",
  text:"We offer several vocational training courses to women from poor communities. These trainings help women boosts their self esteem, as well as provides them self-employment or jobs.",
  img:"/assets/images/women_training.jpg",
  route: '/whatwedo',
},
]

export const ChooseUS=[
{id:1,  
  heading:"Expertise:",
  text:" Our team of immigration providers has years of experience and expertise in helping clients obtain various types of visas, including VISA Switch to UK, General Skilled work visa Tier 2 VISA, Immigrant visas for skilled professionals. We stay up-to-date with the latest immigration laws and regulations to ensure that our clients receive the most accurate and reliable advice.",
  
},
{id:2,
  heading:"Personalized service:",
  text:" We understand that every client's situation is unique, so we provide personalized service tailored to each individual's needs. We take the time to understand your goals and requirements, and work with you to develop a customized immigration strategy that suits your needs."
},
{id:3,
  heading:"Smooth and hassle-free process:",
  text:" We take care of all the paperwork and procedures involved in the immigration process, ensuring a smooth and hassle-free experience for our clients. We guide you through the entire process, from initial consultation to visa application and beyond."
},
{id:4,
  heading:"Affordable pricing:",
  text:" We offer competitive pricing for our services, without compromising on the quality of our work. We believe that everyone should have access to professional immigration services, so we strive to keep our fees reasonable and transparent."
},
{id:5,
  heading:"Responsive communication:",
  text:" We understand the importance of timely communication and keeping our clients informed throughout the process. We are always available to answer your questions and provide updates on your case, ensuring that you are kept informed every step of the way."
},
]

 export const Steps = [
    {
      "id": 1,
      "heading": "Committed to Redefining Abilities & Rescripting Lives",
      "text": "We at Amma Nanna Welfare Association committed non profit social service. We mobilize the most befitting social innovations and innovative models from different parts of the world and deploy them to solve challenges in communities. Keeping in mind the geography, demography, habit, culture and behaviour of the community members, our matching engine discovers and recommends just the right solution.",
      "img": "/assets/images/home_img1.jpg"
    },
    {
      "id": 2,
      "heading": "Hope and Happiness through your support",
      "text": "Amma Nanna Welfare Association team working effectively to make the underprivileged sector aware of their rights and duties. All these are being done with a well-planned program by including the social value in it. Our programmes encourage preventive health, balanced nutrition and child development practices in underprivileged settlements.",
      "img": "/assets/images/home_img2.jpg"
    }
  ];
  

export const photos = [
  {
    "id": 1,
    "img": "/assets/photos/photo1.jpeg"
  },
  {
    "id": 2,
    "img": "/assets/photos/photo2.jpeg"
  },
  {
    "id": 3,
    "img": "/assets/photos/photo3.jpeg"
  },
  {
    "id": 4,
    "img": "/assets/photos/photo4.jpeg"
  },
  {
    "id": 5,
    "img": "/assets/photos/photo5.jpeg"
  },
  {
    "id": 6,
    "img": "/assets/photos/photo6.jpeg"
  },
  {
    "id": 7,
    "img": "/assets/photos/photo7.jpeg"
  },
  {
    "id": 8,
    "img": "/assets/photos/photo8.jpeg"
  }
]

export const videos = [
  {
    "id": 1,
    "embedId": "vlIoN_t5V2M"
  },
  {
    "id": 2,
    "embedId": "t6mY2e2U8WM"
  },
  {
    "id": 3,
    "embedId": "0GgL9zoC-m8"
  },
  {
    "id": 4,
    "embedId": "HGfcGVZGot4"
  }
]