// CardComponent.jsx
import { Card } from '../../ui/card'
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FaArrowCircleRight } from 'react-icons/fa';
import { FaAnglesRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';



const CardComponent = ({ services }: any) => {
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg border-2">
            <img className="w-full" src={services.img} alt=""></img>
            <div className="px-6 py-4 h-25">
                <div className="font-bold text-xl mb-2">{services.heading}</div>
                <p className="text-gray-700 text-base">{services.text}</p>
            </div>
            <div className="px-6 pt-4 pb-2">
                <Link to={services.route} className="inline-block bg-orange-500 rounded-full px-3 py-1 text-xl font-semibold text-white mr-2 mb-2 hover:cursor-pointer">Read more</Link>
            </div>
        </div>
    );
};

export default CardComponent;
