import { useForm } from 'react-hook-form'
import { Card } from '../components/ui/card'
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { FaEnvelope, FaPhone } from 'react-icons/fa6'
import { SiGooglepay, SiPhonepe } from "react-icons/si";
import { Textarea } from '../components/ui/textarea'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

// const formSchema = z.object({
//   fullName: z.string().min(3, { message: 'Full name is required with 3 characters' }),
//   email: z.string().email({ message: 'Invalid email address' }),
//     mobile: z.string().length(10, { message: 'Mobile number must be exactly 10 characters long' }),
//   message: z.string().min(0, { message: 'Message must be at least 0 characters long' }).max(200, { message: 'Message must not exceed 200 characters' }),
// });

const ContactUs = () => {
  // const form = useForm<z.infer<typeof formSchema>>({
  //   resolver: zodResolver(formSchema),
  //   defaultValues: {
  //     fullName: "", email: "", message: "", mobile: ""
  //   },
  // })

  // const onSubmit = async(values: z.infer<typeof formSchema>) =>{
  //   try {
  //     const response = await axios.post("https://ammanannango.com/anapi/sendVisitorInfo", values); 
  //     console.log(response.data);
      
  //     if (response.data) {
  //       toast.success("Contact details sent successfully!");
  //     } else {
  //       toast.error("Failed to send contact details.");
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred while sending the contact details.");
  //   }
  // }
  
  return (<>
    <img src="/assets/images/donate.jpg" alt="Donate Now" className='w-full rounded-lg max-md:hidden'/>
    <div className='wrapper grid md:grid-cols-2 grid-cols-1 gap-6'>
      <div className=' w-full flex flex-col '>
        <div className='space-y-6 my-6'>
      <p className='text-2xl font-semibold border-b-2 border-blue-500 pb-2'>Contact Details:</p>
        <div className='gap-2 p-4 bg-orange-200 border-l-4 border-red-500 rounded-md shadow-lg px-10'>
          <p className='text-black'>Plot No 16, H.No. 10-1-465/1/A,</p>
          <p className='text-black'>Lepakshi Colony,</p>
          <p className='text-black'>West Marredpally,</p>
          <p className='text-black'>Secunderabad - 500 026.</p>
          <p className='text-black'>Telangana, India</p>
        </div>
      <div className='flex flex-col md:flex-row justify-between  gap-4'>
        <div className='md:max-w-[290px] w-full gap-2 p-4 bg-yellow-100 border-l-4 border-yellow-500 rounded-md shadow-lg'>
          <div className="flex flex-row items-center">
            <FaPhone size={22} className='text-blue-900' />
            <div className='px-6'>
              <span>+91 90595 47168</span><br></br>
              <span>+91 96189 47275</span>
            </div>
          </div>
        </div>
        <div className='flex items-center gap-2 w-full p-4 bg-green-100 border-l-4 border-green-500 rounded-md shadow-lg'>
          <FaEnvelope size={24} className='text-green-700' />
          <span className='text-gray-700 hover:text-blue-500 '> seva@ammanannango.com </span>
        </div>
      </div>
    </div>
        </div>
      <div className='pt-6'>
        <p className='text-2xl font-bold'>Please Donate</p> <br></br>
        <div className='w-full gap-2 p-4 bg-orange-200 border-l-4 border-red-800 rounded-md shadow-lg'>
        <div className="flex flex-row items-center">
            <SiPhonepe size={36} className='text-blue-800 m-2' /> <br></br>
            <SiGooglepay size={58} className='text-blue-800 m-2' />
            <div className='px-10'>
              <span className='font-semibold text-xl'>+91 90595 47168</span><br></br>
              <span className='font-semibold text-xl'>+91 96189 47275</span>
            </div>
          </div>
          </div>
        {/* <div className='max-w-xl flex justify-center my-4'>
          <Card className='w-full  p-3'>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Full Name*</FormLabel>
                      <FormControl>
                        <Input placeholder="Name" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Eamil*</FormLabel>
                      <FormControl>
                        <Input placeholder="Email" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="mobile"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Mobile*</FormLabel>
                      <FormControl>
                        <Input placeholder="Mobile" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Message</FormLabel>
                      <FormControl>
                        <Textarea placeholder="Message" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" className='headermenu w-[100px]'>Submit</Button>
              </form>
            </Form>
          </Card>
        </div> */}
      </div>

    </div>
    <ToastContainer/>
    </>
  )
}

export default ContactUs