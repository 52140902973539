import Footer from '../components/shared/RootLayout/Footer';
import Header from '../components/shared/SubLayout/Header';
import React from 'react'
import { Outlet } from 'react-router-dom';

const SubNavigation = () => {
  return (
        <div className="flex flex-col relative font-display">
          <Header />
          <div className="min-h-[500px]">
          <Outlet />
          </div>
          <Footer/>
        </div>
      );

}

export default SubNavigation