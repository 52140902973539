import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";


const CarouselCom = () => {

  return (
    <Carousel 
    autoPlay={true}
    infiniteLoop={true}
    interval={3000}
    showThumbs={false}
    >
<div className="flex flex-col md:flex-row gap-6 justify-between mt-12 w-full bg-gray-100 p-3">
  <div className="md:w-1/2 flex flex-col justify-center ml-4">
    <h5 className="text-4xl font-bold mb-4 text-red-600">NGO For Social Welfare</h5>
    <p className="mb-6 text-justify text-2xl">
      Best Way To Make A Difference In The Lives Of Others. <br></br> Your tax save can provide light in others life.
    </p>
    <Link to="/contactus" className="w-60 bg-red-500 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300">
      Donate Today
    </Link>
  </div>
  <div className="md:w-1/2">
    <img src="/assets/images/anwa_banner.jpg" alt="homeimg" className="w-full rounded shadow-lg" />
  </div>
</div>
<div className="flex flex-col md:flex-row gap-6 justify-between mt-12 w-full bg-gray-100 p-3">
  <div className="md:w-1/2">
    <img src="/assets/images/anwa_banner.jpg" alt="homeimg" className="w-full rounded shadow-lg" />
  </div>
  <div className="md:w-1/2 flex flex-col justify-center  mr-4">
  <h5 className="text-4xl font-bold mb-4 text-red-600">Donate Freshly Cooked Meals With Your Support</h5>
  <p className="mb-6 text-justify text-xl">
  Every meal is packed with the right amount of nutrition, vitamins and proteins that is needed who are destitute of a wholesome meal.
    </p>
    <Link to="/contactus" className="w-60 bg-red-500 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300">
      Donate Today
    </Link>
  </div>

</div>

</Carousel>
  )
}

export default CarouselCom