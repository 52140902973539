import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Card } from '../components/ui/card'

const Whatwedo = () => {
  return (
    <div className=''>
      <div className="wrapper">
      <img src="/assets/images/banner1.jpg" alt=""/>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-5'>
            <Card className="p-2 space-y-3">
              <img className="w-full rounded-lg" src="/assets/images/food_donation.jpg" alt=""></img>
              <p className='font-semibold text-4xl text-orange-600'>Food Distribution</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Distribute quality food regularly.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Driniking water and Butter milk distribution.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Food distribution on special ocations from doners like Birthday, Events, Marriage.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Food parcels for patients in govt hospitals.</p>
            </Card>
            <Card className="p-2 space-y-3">
              <img className="w-full rounded-lg" src="/assets/images/blankets_donation.jpg" alt=""></img>
              <p className='font-semibold text-4xl text-orange-600'>Blankets Distribution</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Blankets doantion for people sleeping on streets during winter.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Blankets for old age people.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Cloths distribution donated by doners.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Blankets for patients in govt hospitals.</p>
            </Card>
          <Card className="p-2 space-y-3">
              <img className="w-full rounded-lg" src="/assets/images/oldage_home.jpg" alt=""></img>
              <p className='font-semibold text-4xl text-orange-600'>Old Age Home Service</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Special service at old age home.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Cloths distribution in old age home.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Festivals and Birth day celebrations at old age home.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Motivation programs for elders who feeling alone.</p>
            </Card>
            <Card className="p-2 space-y-3">
              <img className="w-full rounded-lg" src="/assets/images/medical_service.jpg" alt=""></img>
              <p className='font-semibold text-4xl text-orange-600'>Medical Service</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Medical checkups for poor people.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Medical kits distribution during natural disastor.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Free medicines for poor old age people.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Doctor checkup for elders stay alone.</p>
            </Card>
            <Card className="p-2 space-y-3">
              <img className="w-full rounded-lg" src="/assets/images/books.jpg" alt=""></img>
              <p className='font-semibold text-4xl text-orange-600'>Books for Children</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />School Note books distribution for children.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Stationary items like Pencils, Erasers, Sharpners and Pens for children.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Volunteer trainings to school students.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Skill improvement coachings.</p>
            </Card>
            <Card className="p-2 space-y-3">
              <img className="w-full rounded-lg" src="/assets/images/women_training.jpg" alt=""></img>
              <p className='font-semibold text-4xl text-orange-600'>Women Trainings</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Vocational training courses to women from poor communities.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Training for self emplayment or getting job.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Vocational Training in Tailoring and Embroidery.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Mobilise women in small viable groups and make facilities available through training.</p>
            </Card>
        </div>
      </div>
    </div>
  )
}

export default Whatwedo