import { Card } from '../components/ui/card'
import React from 'react';
import { FaUser } from 'react-icons/fa6';

const AboutUs = () => {
  return (
    <>
    <div className='wrapper md:grid-cols-2 grid grid-cols-1 gap-8'>
      <img src="/assets/images/anwa_banner.jpg" alt="about"  className='w-full rounded-lg'/>
      <div className='space-y-3'>
        <p className='font-semibold text-2xl'>ABOUT US</p>
        <p>Amma Nanna Welfare Association is formed by youngsters who are dedicated their life in social service activities. The commitment of helping poor people is tremendous. We are working hard since more than ten years with completely sacrificing our life in social service.</p>
        <p>Our team recognizes the complexities of the challenges that come with aging. For this reason, Cornerstone is proud to offer personalized care that`s within reach. We are the kind of organization that you can depend on and believe in.</p>
      </div>
    </div>
    <div className='wrapper space-y-3'>
        <p className='font-semibold text-2xl text-orange-600'>OUR TEAM</p>
        <div className="flex py-2">
          <figure className="w-72 text-center">
            <img className="h-auto rounded-lg ml-8 w-32" src="/assets/employee/srilatha.jpg" alt="image description"></img>
            <figcaption className="mt-1 text-sm font-bold">SRILATHA THULAALA</figcaption>
            <figcaption className="text-sm text-center">President</figcaption>
          </figure>
            <div className="item-body px-2 mt-4">
              Mrs. Srilatha Thullala is President and founder for Amma Nanna Welfare Association. She is dedicatedly doing social service activities since more than fifteen years. Conducted many events within rural areas with support of donors.
            </div>
        </div>
      </div>
      <div className='wrapper md:grid-cols-4 grid grid-cols-1 gap-8 items-center'>
        <figure className="w-72 border-2 shadow-md p-4 rounded-lg">
            <img className="px-20 w-60 h-24 rounded" src="/assets/employee/SrishKumar.jpg" alt="Photo"></img>
            <figcaption className="mt-1 text-sm text-center font-bold">K SIRISH KUMAR</figcaption>
            <figcaption className="text-sm text-center">Vice President</figcaption>
        </figure>
        <figure className="w-72 border-2 shadow-md p-4 rounded-lg">
            <img className="px-20 w-60h-24 rounded" src="/assets/icons/user128.png" alt="Photo"></img>
            <figcaption className="mt-1 text-sm text-center font-bold">G SRIKANTH</figcaption>
            <figcaption className="text-sm text-center">General Secretary</figcaption>
        </figure>
        <figure className="w-72 border-2 shadow-md p-4 rounded-lg">
            <img className="px-20 w-60 h-24 rounded" src="/assets/employee/LakshmiNarayana.jpg" alt="Photo"></img>
            <figcaption className="mt-1 text-sm text-center font-bold">M LAKSHMI NARAYANA</figcaption>
            <figcaption className="text-sm text-center">Joint Secretary</figcaption>
        </figure>
        <figure className="w-72 border-2 shadow-md p-4 rounded-lg">
            <img className="px-20 w-60 h-24 rounded" src="/assets/employee/subramanyam.jpg" alt="Photo"></img>
            <figcaption className="mt-1 text-sm text-center font-bold">G S V S S R SUBRAMANYAM</figcaption>
            <figcaption className="text-sm text-center">Treasurer</figcaption>
        </figure>
      </div>
      <div className='wrapper grid md:grid-cols-2 grid-cols-1 gap-10 my-5'>
        <Card className="">
            <p className='font-semibold text-xl text-orange-600 pb-3 px-2'>Executive Members</p>
            <p className='flex gap-1 items-center px-4 py-2'><FaUser size={20} className='text-blue-400' />T Lakshman</p>
            <p className='flex gap-1 items-center px-4 py-2'><FaUser size={20} className='text-blue-400' />R N Bhushan</p>
        </Card>
        <img className="px-20" src="/assets/images/team1.jpg" alt=""></img>
      </div>
    </>

  )
}

export default AboutUs