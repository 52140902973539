import { NavLink } from "react-router-dom";
import MobileNav from "../RootLayout/MobileNav";
import SubHeader from "./SubHeader";


const Header = () => {
  return (
    <header className="w-full bg-white border-b-2 headersticky top-0 z-50">
      <div className="flex-between set-wrap w-full">
          <NavLink to="/"> <img
          alt="logo"
          src="/assets/images/anwa_logo.png"
        className="h-10"
        /></NavLink>
        <div>
        <SubHeader/>
        </div>
        <MobileNav/>
      </div>
    </header>
  );
};

export default Header;


