import VideoAlbumComponent from '../components/shared/VideoAlbum';
import { videos } from '../constants';

const Videos = () => {
  return (
    <>
    <div className='wrapper'>
        <h5 className='text-center font-bold text-4xl my-2 mb-10 '>VIDEOS</h5>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
            {videos.map(vs => <VideoAlbumComponent key={vs.id} embedId={vs.embedId} />)}
        </div>
    </div>
    </>

  )
}

export default Videos