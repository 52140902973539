import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import MobileNav from "./MobileNav";
import SubHeader from "./SubHeader";


const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`w-full top-0 z-50  bg-white  ${isSticky ? 'headersticky  border-b-2 ' : 'headerabsolute bg-opacity-50'}` } >
      <div className="flex justify-between set-wrap w-full">
        <NavLink to="/"> <img
          alt="logo"
          src="/assets/images/anwa_logo.png"
        className="h-10"
        /></NavLink>
        <div>
        <SubHeader/>
        </div>
        <MobileNav/>
      </div>
    </header>
  );
};

export default Header;


