import { Card } from '../ui/card'
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FaArrowCircleRight } from 'react-icons/fa';
import { FaAnglesRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';



const PhotoAlbumComponent = ({ phAlbum }: any) => {
    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg border-2">
            <img className="w-full" src={phAlbum.img} alt=""></img>
        </div>
    );
};

export default PhotoAlbumComponent;
