import PhotoAlbumComponent from '../components/shared/PhotoAlbum';
import { photos } from '../constants';
import React from 'react';

const Photos = () => {
  return (
    <>
    <div className=''>
        <h5 className='text-center font-bold text-4xl my-2 mb-10 '>PHOTOS</h5>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 px-4 py-2">
            {photos.map(vs => <PhotoAlbumComponent key={vs.id} phAlbum={vs} />)}
        </div>
    </div>
    </>

  )
}

export default Photos