import { FaHandshakeSimple, FaRegHandPointRight } from 'react-icons/fa6'
import { ChooseUS, HomeServices, Steps, keyServices } from '../../../constants'
import React from 'react'
import { Slide } from 'react-awesome-reveal';
import CardComponent from './CardComp';
import { RiSearchEyeLine } from "react-icons/ri";
import { FaPeopleCarry } from "react-icons/fa";
import { MdOutlineWheelchairPickup } from "react-icons/md";
import { FaHouseFloodWater } from "react-icons/fa6";

const HomeSub = () => {
    return (
        <div className='wrapper'>
            <div className="my-4">
                <h5 className='text-center font-bold text-4xl mb-6 '>Key Projects</h5>
                <div className='grid md:grid-cols-3 grid-cols-1 gap-4'>
                    <div className="flex flex-col gap-3 p-4 bg-blue-400 justify-center items-center">
                        <FaPeopleCarry size={56} />
                        <p className="text-white font-bold text-2xl">Socail Welfare</p>
                        <p>Provide basic needs like Cloths, Food, Medical Service</p>
                    </div>
                    <div className="flex flex-col gap-3 p-4 bg-green-400 justify-center items-center">
                        <MdOutlineWheelchairPickup size={56} />
                        <p className="text-white font-bold text-2xl">Old Age</p>
                        <p>Treating the elders with care and happyness</p>
                    </div>
                    <div className="flex flex-col gap-3 p-4 bg-red-400 justify-center items-center">
                        <FaHouseFloodWater size={56} />
                        <p className="text-white font-bold text-2xl">Disaster Relief</p>
                        <p>Help people affected with natural calamities</p>
                    </div>
                </div>
            </div>
            <div className="my-4 wrapper">
                <h4 className='text-center font-bold text-4xl mt-2 mb-6'>NGO For Social Welfare</h4>
                {Steps.map(step=><div key={step.id} className='grid md:grid-cols-2 md:gap-10 my-10'>
                    <p className="font-bold text-2xl md:mt-10  max-md:block hidden my-4">{step.heading}</p>
                    <img src={step.img} alt="step.text" className='w-full max-md:block hidden rounded-lg' />
                <Slide direction="left" triggerOnce> {step.id % 2 !== 0 && <img src={step.img} alt="ser.text" className='md:block hidden rounded-lg' />}</Slide>
                    <div>
                    <p className="font-bold text-2xl md:mt-10 md:block hidden text-center my-4">{step.heading}</p>
                    <p className=" mb-4 text-justify my-1 leading-7">{step.text}</p>
                    </div>
                    <Slide direction="right" triggerOnce> {step.id % 2 === 0 && <img src={step.img} alt="ser.text" className='md:block hidden rounded-lg' />}</Slide>
                
                </div>)}
            </div>
            <div className=''>
                <h5 className='text-center font-bold text-4xl my-2 mb-10 '>What We Do</h5>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-6">
                    {keyServices.map(vs => <CardComponent key={vs.id} services={vs} />)}
                </div>
            </div>
        </div>
    )
}

export default HomeSub