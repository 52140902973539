import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<StrictMode>
  <HelmetProvider context={helmetContext}>
    <App />
  </HelmetProvider>
  </StrictMode>)
