import HomeSub from "../components/shared/Home/HomeSub"
import CarouselCom from "../components/shared/Home/Carousel";
import { Button } from "../components/ui/button";
import { IoLogoWhatsapp } from "react-icons/io";


const Home = () => {
  const shareOnWhatsApp = () => {
    const phoneNumber = '9618947275'; // The phone number you want to chat with
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };
  return (
    <>
      <CarouselCom />
      <div className="fixed  bottom-4 right-4">
        <Button variant="ghost" onClick={shareOnWhatsApp} ><IoLogoWhatsapp  size={40} color="green"/></Button>
      </div>
      <div>
        <HomeSub />
      </div>
    </>
  )
}




export default Home


