import { Card } from '../ui/card'
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FaArrowCircleRight } from 'react-icons/fa';
import { FaAnglesRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';


const VideoAlbumComponent = ({ embedId  }: any) => {
    return (
            <div className="video-responsive">
                <iframe width="560" 
                    height="315" 
                    src={`https://www.youtube.com/embed/${embedId}`}
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
    );
};

export default VideoAlbumComponent;
